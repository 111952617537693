














import { useIsMobile } from '../helpers/mobileDevices';
const __sfc_main = {
  layout: 'defaultWithoutFooter'
};
__sfc_main.props = {
  error: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isMobile = useIsMobile();
  return {
    isMobile
  };
};
export default __sfc_main;
