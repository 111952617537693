
























import { ref, onBeforeMount } from '@nuxtjs/composition-api';
import debounce from 'lodash.debounce';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Products, ProductInterface } from '~/modules/GraphQL/types';
import SearchResults from './SearchResults.vue';
import SearchBar from './SearchBar.vue';
import productsList from '../../../customQueries/productsListQuery';
import { ProductRepository } from '../../../repository/products.repository';
import { useApi } from '~/composables/useApi';
const __sfc_main = {};
__sfc_main.props = {
  isSearchOpen: {
    type: Boolean,
    default: false
  },
  itemsPerPage: {
    type: Number,
    default: 12
  },
  minTermLen: {
    type: Number,
    default: 0
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const {
    query
  } = useApi();
  const term = ref('');
  const productSearchResults = ref<ProductInterface[] | null>(null);
  const searchTerm = ref<string>('null');
  const debouncedSearchTerm = ref('');
  const loadingProduct = ref<ProductInterface[]>(null);
  const {
    getProductList
  } = useProduct();
  const productRepository = new ProductRepository();
  const bestProducts = ref<ProductInterface[] | null>(null);
  const getBestProductSkuSearch = async () => {
    try {
      const bestProductSku = await productRepository.getBestProduct();
      const response = await query(productsList, {
        filter: {
          sku: {
            in: bestProductSku
          }
        }
      });
      bestProducts.value = response?.products?.items || [];
    } catch (e) {
      console.error('Erreur lors de la récupération des produits:', e);
    }
  };
  onBeforeMount(async () => {
    await getBestProductSkuSearch();
  });
  const showSearch = () => {
    if (!props.isSearchOpen) {
      emit('set-is-open', true);
      emit('SearchBar:toggle', true);
      document.body.classList.add('no-scroll');
    }
  };
  const hideSearch = () => {
    emit('set-is-open', false);
    emit('set-search-results', null);
    document.body.classList.remove('no-scroll');
  };
  const closeSearch = (event: MouseEvent) => {
    const searchResultsEl = document.querySelector('.search');
    const closeTriggerElement = (event.target as HTMLElement);
    if (!searchResultsEl?.contains(closeTriggerElement)) {
      hideSearch();
      term.value = '';
    }
  };
  const debouncedSearch = debounce(async () => {
    emit('set-loading-product', true);
    emit('set-debounced-search-term', term.value);
    if (term.value.length < props.minTermLen) return;
    const productList: Products = await getProductList({
      pageSize: props.itemsPerPage,
      search: term.value
    });
    emit('set-loading-product', false);
    emit('set-search-results', productList!.items);
  }, 1000);
  const handleSearch = () => {
    emit('set-search-term', term.value);
    debouncedSearch();
    showSearch();
  };
  const closeSearchModal = () => {
    emit('update:closeSearchModal');
  };
  return {
    productSearchResults,
    searchTerm,
    debouncedSearchTerm,
    loadingProduct,
    bestProducts,
    closeSearch,
    closeSearchModal
  };
};
__sfc_main.components = Object.assign({
  SearchBar,
  SearchResults
}, __sfc_main.components);
export default __sfc_main;
