import gql from 'graphql-tag';

/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default gql`
  query productsList(
    $search: String = ""
    $filter: ProductAttributeFilterInput
    $pageSize: Int = 10
    $currentPage: Int = 1
    $sort: ProductAttributeSortInput
  ) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      aggregations {
        attribute_code
        label
        options {
          label
          value
          count
        }
      }
      items {
        uid
        sku
        name
        stock_status
        only_x_left_in_stock
        rating_summary
        product_preview
        thumbnail {
          url
          position
          disabled
          label
        }
        url_key
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        categories {
          uid
          name
          url_suffix
          url_path
          breadcrumbs {
            category_name
            category_url_path
          }
        }
        review_count
        reviews {
          items {
            average_rating
            ratings_breakdown {
              name
              value
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
            label
            position
            uid
            use_default
            values {
              label
              swatch_data {
                value
              }
              uid
            }
          }
          variants {
            product {
              sku
              name
              only_x_left_in_stock
              filtrable_color
              thumbnail {
                url
                label
              }
              media_gallery {
                url
                position
                disabled
                label
              }
              price_range {
                maximum_price {
                  final_price {
                    value
                  }
                  regular_price {
                    value
                  }
                }
                minimum_price {
                  final_price {
                    value
                  }
                  regular_price {
                    value
                  }
                }
              }
            }
            attributes {
              code
              label
              uid
              value_index
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
