













import { SfLoader } from '@storefront-ui/vue';
import { computed } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  text: {
    type: String,
    required: true,
    default: 'Button'
  },
  color: {
    type: String,
    required: false,
    default: '#FFFFF'
  },
  width: {
    type: String,
    required: false,
    default: 'auto'
  },
  height: {
    type: String,
    required: false,
    default: 'auto'
  },
  load: {
    type: Boolean,
    required: true,
    default: false
  },
  disable: {
    type: Boolean,
    required: false,
    default: false
  },
  dataCy: {
    type: String,
    required: true,
    default: 'dark_button_without_data-cy'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const buttonStyle = computed(() => {
    return {
      backgroundColor: props.color,
      width: props.width,
      height: props.height,
      opacity: props.disable ? 0.1 : 1
    };
  });
  const clickFunction = () => {
    if (!props.load && !props.disable) {
      emit('clickFunction');
    }
  };
  return {
    buttonStyle,
    clickFunction
  };
};
__sfc_main.components = Object.assign({
  SfLoader
}, __sfc_main.components);
export default __sfc_main;
