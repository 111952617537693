





















import { ref, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { CategoryTree } from '~/modules/GraphQL/types';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import HeaderLogo from './HeaderLogo.vue';
import StoreSwitcher from './StoreSwitcher/StoreSwitcher.vue';
import HeaderShortcuts from '@/components/Header/Shortcuts/Shortcut.vue';
import PriceHider from '@/components/Header/PriceHider/PriceHider.vue';
import Search from './SearchBar/Search.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    categories: categoryList,
    load: categoriesListLoad
  } = useCategory();
  const categoryTree = ref<CategoryTree[]>([]);
  const isSearchOpen = ref<boolean>(false);
  const searchRef = ref(null);
  const searchIconRef = ref(document.getElementsByClassName('icon-Search'));
  const handleSearch = () => {
    isSearchOpen.value = !isSearchOpen.value;
    if (isSearchOpen.value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };
  const closeSearch = () => {
    isSearchOpen.value = false;
    document.body.style.overflow = 'auto';
  };
  const handleClickOutside = (event: MouseEvent) => {
    const target = (event.target as HTMLElement);
    if (!searchRef.value?.contains(target) && !searchIconRef.value[0]?.contains(target)) {
      closeSearch();
    }
  };
  onMounted(async () => {
    await categoriesListLoad({
      pageSize: 20
    });
    if (categoryList.value?.[0]?.children) {
      categoryTree.value = categoryList.value?.[0]?.children.filter(category => category.include_in_menu);
    }
    document.addEventListener('click', handleClickOutside);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });
  return {
    emit,
    categoryTree,
    isSearchOpen,
    searchRef,
    handleSearch,
    closeSearch
  };
};
__sfc_main.components = Object.assign({
  HeaderNavigation,
  HeaderLogo,
  PriceHider,
  StoreSwitcher,
  HeaderShortcuts,
  Search
}, __sfc_main.components);
export default __sfc_main;
