import findDeep from 'deepdash/findDeep';
import { CategoryTree } from '~/modules/GraphQL/types';

/**
 * Traverse Category Tree to find and return active category by "findBy" param
 *
 * @param categoryTree CategoryTree
 * @param toFind string
 * @param findBy string (default = url_path)
 *
 * @return CategoryTree
 */
export function findActiveCategory(
  categoryTree: CategoryTree, 
  toFind: string, 
  findBy: keyof CategoryTree = 'url_path'
): CategoryTree | null {
  const categories = categoryTree?.children;
  
  toFind = toFind.replace('?sort=name_ASC&page=0', '');

  if (!categories) {
    return null;
  }

  const activeCategory = findDeep(
    categories,
    (value: string, key: string) => key === findBy && value.includes(toFind)
  )?.parent ?? null;

  if (!activeCategory) {
    return null;
  }

  let parentCategoryName = activeCategory.name;

  if (
    !activeCategory.children?.length &&
    activeCategory.url_path &&
    activeCategory.url_path.split('/').length === 3 
  ) {
    const parentCategory = findDeep(
      categories,
      (_, key: string, node) =>
        node.children?.some((child) => child.uid === activeCategory.uid)
    )?.parent ?? null;

    if (parentCategory?.children?.length) {
      parentCategoryName = parentCategory.name;

      return {
        ...activeCategory,
        children: parentCategory.children,
        parentCategoryName,
      };
    }
  }

  return {
    ...activeCategory,
    parentCategoryName,
  };
}

export function findActiveCategoryMenu(categoryTree: CategoryTree, toFind: string, findBy: keyof CategoryTree = 'url_path'): CategoryTree | null {
  const categories = categoryTree?.children;
  toFind = toFind.replace('?sort=name_ASC&page=0', '')
  const categorySelected = categories.find(children => children.url_path === toFind);
  return categorySelected ;
}