























































































































































import { SfSidebar, SfList, SfAccordion } from '@storefront-ui/vue';
import { useRouter, useContext, computed } from '@nuxtjs/composition-api';
import { useUiHelpers, useUiState, useUser } from '~/composables';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { CategoryTree } from '~/modules/GraphQL/types';
import StoreSwitcher from '@/components/Header/StoreSwitcher/StoreSwitcher.vue';
import HeaderLogo from '@/components/Header/HeaderLogo.vue';
import HeaderShortcuts from '@/components/Header/Shortcuts/Shortcut.vue';
import iconMobileJson from '@/components/Header/Shortcuts/Icons-mobile.json';
import iconPlus from '@/assets/icons/icon-plus.svg';
import iconMinus from '@/assets/icons/icon-minus.svg';
import { useIsTablet } from '@/helpers/mobileDevices';
import NetworkButton from '@/components/General/Button/NetworkButton.vue';
import { useRedirectToNetwork } from '@/helpers/redirectNetwork';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    isTablet
  } = useIsTablet();
  const {
    toggleMobileMenu
  } = useUiState();
  const router = useRouter();
  const app = useContext();
  const {
    categoryAncestors: initianulllistory,
    categoryTree
  } = useTraverseCategory();
  const {
    toggleLoginModal
  } = useUiState();
  const {
    isAuthenticated
  } = useUser();
  const {
    getCatLink
  } = useUiHelpers();
  const navigate = (category: CategoryTree) => {
    const path = getCatLink(category) != '/c/undefined' ? app.localePath((getCatLink(category) as string)) : app.localePath('/c/robes.html');
    return router.push(path);
  };
  const itemsIncludedInMenu = computed(() => {
    return categoryTree.value.children;
  });
  const handleFindMyOrder = async () => {
    if (isAuthenticated.value === true) {
      router.push(app.localeRoute({
        name: 'customer-order-history'
      }));
      toggleMobileMenu();
    } else {
      toggleLoginModal();
    }
  };
  const pathType = (category: string) => {
    const cat = {
      url_path: category,
      url_suffix: '.html'
    };
    navigate(cat);
  };
  const localRouteType = (category: string) => {
    router.push(app.localeRoute(category));
  };
  const handleClick = (event: Event, type: string, category: string) => {
    event.stopPropagation();
    toggleMobileMenu();
    const authorisedType = ['path', 'localRoute', 'auto'];
    if (!authorisedType.includes(type)) {
      console.error('type not authorised');
      localRouteType('home');
      return;
    }
    switch (type) {
      case 'path':
        {
          return pathType(category);
        }
      case 'localRoute':
        {
          return localRouteType(category);
        }
      case 'auto':
        {
          return navigate(category);
        }
      default:
        {
          console.error('Unknown type');
          localRouteType('home');
        }
    }
  };
  const redirectToNetwork = async (link: string) => {
    window.open(useRedirectToNetwork(link), '_blank');
  };
  return {
    iconMobileJson,
    iconPlus,
    iconMinus,
    isTablet,
    toggleMobileMenu,
    itemsIncludedInMenu,
    handleFindMyOrder,
    handleClick,
    redirectToNetwork
  };
};
__sfc_main.components = Object.assign({
  SfSidebar,
  HeaderLogo,
  HeaderShortcuts,
  SfList,
  SfAccordion,
  NetworkButton,
  StoreSwitcher
}, __sfc_main.components);
export default __sfc_main;
