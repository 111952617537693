import { useApi } from '~/composables/useApi';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';

export function useProductCard() {
  const { query } = useApi();
  const { getProductDetails } = useProduct();

  const getGalleryByColor = async (color: string, product, mediaGalleryConfigurable): Promise<[][]> => {
    let productMedia;
    const colorConfiguration = product?.find((option) => option.attributes[1].uid === color)?.attributes[1]?.label;
    const productConfiguration = product?.find((variant) => {
      return variant.attributes.some((attribute) => attribute.label === colorConfiguration);
    });
    if (productConfiguration?.product?.media_gallery.length === 0) {
      productMedia = mediaGalleryConfigurable;
    } else if (productConfiguration?.product?.media_gallery.length === 1) {
      const variantWithMultipleMedia = product.find((variant) => {
        return variant.product.media_gallery.length > 1;
      });
      productMedia = [...productConfiguration.product.media_gallery, variantWithMultipleMedia?.product.media_gallery[1]];
    } else {
      productMedia = productConfiguration?.product?.media_gallery;
    }
    return productMedia;
  };

  const getColorConfiguration = async (configurableOptions, filter: string | null, parentColors): Promise<[][]> => {
    let configuration = [];
    let attribute;
    let uid = [];
    let colorSelectedParents;

    configurableOptions?.forEach((element) => {
      colorSelectedParents = parentColors !== null ? parentColors.find((color) => color.color === element.uid) : null;
      uid.push({
        uid: element.uid,
        label: element.label,
        swatch_data: element.swatch_data?.value,
        haveFilter: colorSelectedParents ? (colorSelectedParents?.parentColor?.includes(',') ? null : colorSelectedParents.parentColor) : null,
      });
    });

    uid.forEach(async (item, index) => {
      if (filter !== null && filter === item.haveFilter) {
        attribute = item.uid;
      } else if (index === 0 && item.haveFilter === null) {
        attribute = item.uid;
      } else if (index === 0) {
        attribute = item.uid;
      }

      const obj = {
        uid: item.uid,
        label: item.label,
        swatch_data: item.swatch_data,
        selected: filter === item.haveFilter ? true : index === 0 ? true : false,
        haveFilter: item.haveFilter,
      };
      configuration.push(obj);
    });

    return [configuration, attribute];
  };

  const getSizeConfiguration = async (productVariant, colorUid: string): Promise<[][]> => {
    let configuration = [];
    let attribute;
    let uid = [];

    if (productVariant && colorUid) {
      productVariant.configurable_options[0].values.forEach((element) => {
        const matchingVariant = productVariant.variants.find(
          (variant) => variant.attributes[0].uid === element.uid && variant.attributes[1].uid === colorUid
        );
        const stockStatus = matchingVariant?.product.only_x_left_in_stock ?? null;

        uid.push({
          uid: element.uid,
          label: element.label,
          swatch_data: element.swatch_data?.value,
          disabled: stockStatus === null,
        });
      });

      uid.forEach((item, index) => {
        const obj = {
          uid: item.uid,
          label: item.label,
          swatch_data: item.swatch_data,
          selected: index === 0,
          disabled: item.disabled,
        };

        if (!attribute && !item.disabled) {
          attribute = item.uid;
          obj.selected = true;
        }

        configuration.push(obj);
      });
    }

    return [configuration, attribute];
  };

  const selectedVariant = async (matchingVariant: any, colorUid, sku: string): Promise<[][]> => {
    const productConfiguration = [matchingVariant.attributes[0].uid, colorUid];
    const result = await getProductDetails({
      filter: {
        sku: {
          eq: sku,
        },
      },
      configurations: Object.entries(productConfiguration).map((config) => config[1]),
    });
    return result.items[0].configurable_product_options_selection.variant;
  };

  const isNewProduct = (product) => {
    const categoriesNew = product?.categories?.find((category) => category.name === 'Nouveautés' || category.name === 'News');
    return categoriesNew ? true : false;
  };

  const getParentsColor = async (products) => {
    let colorsParents = [];

    products?.products?.items[0]?.variants.forEach((variant) => {
      colorsParents.push({
        color: variant.attributes[1].uid,
        parentColor: variant?.product?.filtrable_color,
      });
    });

    return colorsParents;
  };

  return {
    getGalleryByColor,
    getSizeConfiguration,
    getColorConfiguration,
    selectedVariant,
    isNewProduct,
    getParentsColor,
  };
}
export default useProductCard;
