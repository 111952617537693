import axios, { AxiosResponse } from 'axios';
import { RedisService } from '../service/redis.service'
export class ProductRepository {
  constructor(public redisService = new RedisService()) {}
  readonly baseUrlStore = process.env.VSF_STORE_URL;
  
  public async getAllProducts(pageInfo) {
    const cacheKey = `category_products_${pageInfo.categoryId}_${pageInfo.currentPage}_${pageInfo.pageSize}_${pageInfo.userPreview}_${pageInfo.sortField}_${pageInfo.sortValue}`;
    const redisService = new RedisService();

    try {
      return await this.redisService.GetRedis(cacheKey);
    } catch (error) {
      console.error('Erreur lors de la récupération du cache:', error);
    }

    try {
      const response = await axios.post(`${this.baseUrlStore}getAllProducts`, {
        categoryId: pageInfo.categoryId,
        currentPage: pageInfo.currentPage,
        pageSize: pageInfo.pageSize,
        userPreview: pageInfo.userPreview,
        sortField: pageInfo.sortField,
        sortValue: pageInfo.sortValue,
      });

      try {
        await this.redisService.SetRedis(cacheKey, JSON.stringify(response.data));
      } catch (error) {
        console.error('Erreur lors de l\'ajout du cache:', error);
      }
      
      return response.data;
    } catch (error) {
      throw JSON.stringify(error);
    }
  }


  public async getProductSku(sku) {
    const cacheKey = `product_sku_${sku}`;
    
    try {
      return await this.redisService.GetRedis(cacheKey);
    } catch (error) {
      console.error('Erreur lors de la récupération du cache:', error);
    }

    try {
      const adminToken = await axios.post(`${this.baseUrlStore}getAdminToken`);
      const response = await axios.get(`${this.baseUrlStore}getProductSku?sku=${sku}&adminToken=${adminToken.data}`);

      try {
        await this.redisService.SetRedis(cacheKey, JSON.stringify(response.data));
      } catch (error) {
        console.error('Erreur lors de l\'ajout du cache:', error);
      }

      return response.data;
    } catch (error) {
      throw JSON.stringify(error);
    }
  }

  public async getBestProduct() {
    try {
      const adminToken = await axios.post(`${this.baseUrlStore}getAdminToken`);
      const response = await axios.get(`${this.baseUrlStore}getBestProduct?&adminToken=${adminToken.data}`);
      return response.data;
    } catch (error) {
      throw JSON.stringify(error);
    }
  }

  public async getBestCategory() {
    try {
      const adminToken = await axios.post(`${this.baseUrlStore}getAdminToken`);
      const response = await axios.get(`${this.baseUrlStore}getBestCategory?&adminToken=${adminToken.data}`);
      return response.data;
    } catch (error) {
      throw JSON.stringify(error);
    }
  }
}
