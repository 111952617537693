
























import { useRouter, useContext, computed, onMounted } from '@nuxtjs/composition-api';
import { SfButton, SfBadge } from '@storefront-ui/vue';
import { useUiState } from '@/composables';
import { useWishlistStore } from '@/modules/wishlist/store/wishlistStore';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
const __sfc_main = {};
__sfc_main.props = {
  item: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const router = useRouter();
  const {
    app
  } = useContext();
  const {
    isAuthenticated
  } = useUser();
  const {
    toggleLoginModal
  } = useUiState();
  const wishlistStore = useWishlistStore();
  const {
    loadItemsCount: loadWishlistItemsCount
  } = useWishlist();
  const {
    loadTotalQty: loadCartTotalQty,
    cart
  } = useCart();
  const redirectToIcons = (link: string, name: string) => {
    switch (name) {
      case 'Account':
      case 'AccountM':
        router.push(app.localeRoute({
          name: link
        }));
        break;
      case 'Contact':
        router.push(app.localeRoute({
          name: link
        }));
        break;
      case 'Map':
        router.push(app.localeRoute({
          name: link
        }));
        break;
      case 'Search':
        emit('launchSearch');
        break;
      case 'Wishlist':
        router.push(app.localeRoute({
          name: link
        }));
        break;
      case 'Cart':
      case 'CartM':
        router.push(app.localeRoute({
          name: link
        }));
        break;
      default:
        router.push(app.localeRoute('/'));
    }
  };
  if (props.item.name == 'Wishlist') {
    props.item.badgeValue = computed(() => wishlistStore.wishlist?.items_count ?? 0);
  }
  if (props.item.name == 'Cart' || props.item.name == 'CartM') {
    props.item.badgeValue = computed(() => cart.value?.total_quantity ?? 0);
  }
  onMounted(async () => {
    if (props.item.name === 'Cart' || props.item.name === 'CartM') {
      await loadCartTotalQty();
    }
    if (props.item.name == 'Wishlist') {
      await loadWishlistItemsCount({});
    }
  });
  return {
    isAuthenticated,
    redirectToIcons
  };
};
__sfc_main.components = Object.assign({
  SfButton,
  SfBadge
}, __sfc_main.components);
export default __sfc_main;
